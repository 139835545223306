<!-- 培训班级类别——班级 -->
<template>
    <div class="ClassData">
        <!-- 上面部分 -->
        <div class="top">
            <span style="font-size: 16px; font-weight: bold;">培训班级类别—班级</span>
            <el-button class="el-btn" size="small" @click="goBack">
                返回
            </el-button>
        </div>
        <!-- 查询表单 -->
        <!-- <el-form ref="form" :model="form" inline class="form" label-width="80px">
            <el-form-item label="搜索">
                <el-input v-model="form.search" placeholder="请输入关键字"></el-input>
            </el-form-item>
            <el-form-item label="选择学科">
                <el-input v-model="form.subject" placeholder="请输入学科"></el-input>
            </el-form-item>
            <el-form-item label="选择">
                <el-select v-model="form.choice" placeholder="请选择" :clearable="true">
                    <el-option v-for="item in form.choiceOption" :key="item.value" :label="item.label"
                        :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
            <div class="" style="margin-left: 25px;margin-top: 5px;">
                <el-button size="small" style="background: #18BC9C;color: #FFFFFF;">
                    查询
                </el-button>
                <el-button size="small" style="background: #FAFAFA;color: #666666;">
                    重置
                </el-button>
            </div>
            
        </el-form> -->
        <div class="" style="margin-left: 21px;">
            <el-button size="small" icon="el-icon-refresh" style="background-color: #2C3E50; border-radius: 3px;"
                @click="refresh">
                <!-- 刷新 -->
            </el-button>
        </div>
        <!-- 班级 列表 -->
        <el-table :data="classList" border stripe style="width:97.9%;flex: 1;" height="650" @sort-change="abc">
            <el-table-column type="index" label="序号" align="center" width="80">
            </el-table-column>
            <el-table-column prop="category_status"  :sortable="true" label="状态" align="center" width="100">
                <template slot-scope="scope">
                    <span v-if="scope.row.category_status==1">准备</span>
                    <span v-else-if="scope.row.category_status==2">启用</span>
                    <span v-else-if="scope.row.category_status==3">停用</span>
                </template>
            </el-table-column>
            <el-table-column prop="number" :sortable="true" label="编码" align="center" width="150">
            </el-table-column>
            <el-table-column prop="" :sortable="true" label="班类型" align="center" width="120">
            </el-table-column>
            <el-table-column prop="category_name" :sortable="true" label="名称" align="center" width="210">
            </el-table-column>
            <el-table-column prop="resources" :sortable="true"  label="主责老师" align="center" width="120">
            </el-table-column>
            <el-table-column prop="course" :sortable="true" label="学科" align="center" width="200">
            </el-table-column>
            <el-table-column prop="tuition_incidentals" :sortable="true" label="学杂费/人" align="center" width="200">
            </el-table-column>
            <el-table-column prop="tuition" :sortable="true" label="学费" align="center" width="150">
            </el-table-column>
            <el-table-column prop="incidentals" :sortable="true" label="杂费" align="center" width="150">
            </el-table-column>
            <el-table-column prop="category_size" :sortable="true" label="计划招生数" align="center" width="150">
            </el-table-column>
            <el-table-column prop="start_time" :sortable="true" label="开班日期" align="center" width="200">
            </el-table-column>
            <el-table-column prop="end_time" :sortable="true" label="结业日期" align="center" width="200">
            </el-table-column>
            <el-table-column prop="lecture_number" :sortable="true" label="授课次数" align="center" width="150">
            </el-table-column>
            <el-table-column prop="remark" :sortable="true" label="说明" align="center" width="300">
            </el-table-column>
        </el-table>
        <!--分页 -->
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage"
            :page-sizes="[20,50,100,200,500,1000]" :page-size="10" background
            layout="total, sizes, prev, pager, next, jumper" :total="total">
        </el-pagination>
    </div>
</template>

<script>
    export default{
        data() {
            return{
                form: {
                    search: "", //搜索
                    subject: "", //选择学科
                    choice: "", //选择
                    choiceOption: [{
                            value: '0',
                            label: '全部'
                        },
                        {
                            value: '1',
                            label: '准备'
                        },
                        {
                            value: '2',
                            label: '启用'
                        },
                        {
                            value: '3',
                            label: '停用'
                        }
                    ] //选择选项
                },
                classList: [], //班级数据
                currentPage: 1,
                currentLimit: 20,//条数
                total: 0,
                arr:[]
            }
        },
        created() {
            this.getList()
        },
        activated() {
            this.getList()
        },
        methods:{
            getList(){
                this.$request({
                    url:'/api/classcategory/list',
                    method:"POST",
                    data:{
                        class_category_id:this.$route.query.class_category_id,
                        page:this.currentPage,
                        limit:this.currentLimit,
                        sort:this.arr
                    }
                }).then(res=>{
                    if(res.code==1){
                        // console.log(res,1111)
                        this.classList=res.data.list
                        this.total=res.data.count
                    }
                })
            },
            goBack() { //返回
                this.$router.go(-1);
            },
            // 刷新
            refresh() {
                this.$router.push({
                    path: "/home/newpage", //空页面路由
                    query: {
                        path: this.$route.path
                    }
                })
            },
            goBack() { //返回
                this.$router.go(-1);
            },
            handleSizeChange(val) {
                this.currentLimit=val
                this.getList()
            },
            handleCurrentChange(val) {
                this.currentPage=val
                this.getList()
            },
            // 姓名排序
            fn2(a, b){
                return a.resources.localeCompare(b.resources)
            },
            //排序
            abc(i){ 
                console.log(i)
                this.arr=[]
                this.arr.push({
                    title:i.prop,
                    order:i.order
                })
                console.log(this.arr)
                this.getList()
            }
        }
    }
</script>

<style scoped="scoped">
    .ClassData{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
    }
    /* 上面部分 */
    .top {
        padding-top: 25px;
        margin: 0px 24px 5px 28px;
        display: flex;
        justify-content: space-between;
    }
    
    .el-btn {
        background: #18BC9C;
        padding-left: 25px;
        background-position: 5px 7px;
        display: inline-block;
        background-image: url(../../assets/back.png);
        background-repeat: no-repeat;
        color: #FFFFFF;
    }
     /* 查询部分 */
    .form {
        padding-top: 20px;
        display: flex;
        flex-wrap: wrap;
    }

    .form ::v-deep .el-input__inner {
        width: 276px;
        height: 37px;
    }

    .form ::v-deep .el-form-item__label {
        color: #000000;
    }
    /* 刷新按钮 */
    ::v-deep .el-icon-refresh {
        font-weight: bold !important;
        color: #FFFFFF;
    }
    /* 表格 */
    .el-table {
        margin: 20px;
    }
    
    ::v-deep .el-table__cell {
        color: #000;
        padding: 0;
        height: 30px;
    }
    /* 分页 */
    .el-pagination {
        margin: 20px;
        margin-top: 0px;
    }

    ::v-deep.el-pagination.is-background .el-pager li:not(.disabled).active {
        background-color: #2C3E50 !important;
    }
</style>
